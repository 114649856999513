import * as React from 'react'

const Success = (props) => (
  <svg
    {...props}
    width='64'
    height='70'
    viewBox='0 0 64 70'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
  >
    <path
      d='M52.3098 34.2273C52.3098 34.2273 56.3671 69 27.0001 69C-2.36686 69 1.69013 34.2273 1.69013 34.2273C1.69013 34.2273 1.69013 1 27 1C52.3098 1 52.3098 34.2273 52.3098 34.2273Z'
      stroke='#6FCF97'
      stroke-width='2'
    />
    <path d='M15.5 32.2357L25.8459 46L62.5 5' stroke='#6FCF97' stroke-width='2' />
  </svg>
)

export default Success
