import * as React from 'react'

import Button from '@/components/Button'

const Buttons = ({buttons = [], className, buttonProps = {}}) => {
  if (buttons.length > 0) {
    return (
      <div className={className}>
        {buttons.map(({_uid, component, ...button}) => {
          switch (component) {
            case 'button-primary':
              buttonProps.theme = 'primary'
              break
            case 'button-secondary':
              buttonProps.theme = 'secondary'
              break
            default:
          }

          return <Button key={_uid} {...button} {...buttonProps} />
        })}
      </div>
    )
  }

  return null
}

export default Buttons
