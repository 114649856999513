import * as React from 'react'

function IconPlus({title, titleId, ...props}) {
  return (
    <svg
      width={32}
      height={32}
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      aria-labelledby={titleId}
      {...props}
    >
      {title ? <title id={titleId}>{title}</title> : null}
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M16.5 16.5V26h-1v-9.5H6v-1h9.5V6h1v9.5H26v1h-9.5z'
        fill='#171717'
      />
    </svg>
  )
}

export default IconPlus
