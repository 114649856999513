import classNames from 'classnames'
import gsap from 'gsap'
import {ScrollTrigger} from 'gsap/dist/ScrollTrigger'
import React, {useEffect, useRef} from 'react'

import ButtonV2 from '@/components/__deprecated/ButtonV2'
import Image from '@/components/Image'

import * as styles from './SectionV2.module.scss'

gsap.registerPlugin(ScrollTrigger)

const SectionV2 = ({
  className,
  tag,
  heading,
  paragraph,
  buttonLabel,
  buttonLink,
  image,
  layout,
  theme,
  gradient,
}) => {
  const sharedClassName = classNames(className, {
    [styles.column]: layout === 'column',
    [styles.columnReversed]: layout === 'column reversed',
    [styles.row]: layout === 'row',
    [styles.rowReversed]: layout === 'row reversed',

    [styles.light]: theme === 'light',
    [styles.dark]: theme === 'dark',
    [styles.yellow]: theme === 'yellow',

    [styles.noGradient]: gradient === 'none',
    [styles.gradientTop]: gradient === 'top',
    [styles.gradientBottom]: gradient === 'bottom',
  })

  const imageRef = useRef()

  useEffect(() => {
    const image = imageRef.current

    if (image) {
      const context = gsap.context(() => {
        gsap.fromTo(
          image,
          {yPercent: '+=25', opacity: 0},
          {
            yPercent: 0,
            opacity: 1,
            duration: 1,
            ease: 'power3.out',
            scrollTrigger: {
              trigger: image,
            },
          }
        )
      })

      return () => context.revert()
    }
  }, [])

  return (
    <section className={classNames(styles.section, sharedClassName)}>
      <div className={classNames(styles.gradients, sharedClassName)}>
        <div className={classNames(styles.gradient, sharedClassName)} />
        <div className={classNames(styles.gradient, sharedClassName)} />
      </div>

      <div className={classNames(styles.container, sharedClassName)}>
        <article className={styles.content}>
          {tag && <p className={styles.tag}>{tag}</p>}
          {heading && <h2 className={styles.heading}>{heading}</h2>}
          {paragraph && <p className={styles.paragraph}>{paragraph}</p>}
          {buttonLabel && buttonLink && (
            <ButtonV2
              className={styles.button}
              label={buttonLabel}
              link={buttonLink}
              buttonStyle={'fill'}
              color={theme === 'light' || theme === 'yellow' ? 'dark' : 'light'}
            />
          )}
        </article>

        {image && image.filename && (
          <div className={styles.picture} ref={imageRef}>
            <Image className={styles.image} {...image} />
          </div>
        )}
      </div>
    </section>
  )
}

export default SectionV2
