import * as React from 'react'

const Circles = ({className}) => (
  <svg
    className={className}
    preserveAspectRatio='none'
    viewBox='0 0 319 320'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
  >
    <g clip-path='url(#clip0_11_3782)'>
      <path
        d='M131.04 118.342C138.307 180.939 183.329 250.328 231.599 273.327C279.869 296.327 313.109 264.227 305.842 201.63C298.575 139.033 253.553 69.6434 205.283 46.6441C157.012 23.6447 123.773 55.7448 131.04 118.342Z'
        stroke='url(#paint0_linear_11_3782)'
      />
      <path
        d='M92.78 118.342C100.047 180.939 145.069 250.328 193.339 273.327C241.609 296.327 274.849 264.227 267.582 201.63C260.315 139.033 215.293 69.6434 167.023 46.6441C118.753 23.6447 85.513 55.7448 92.78 118.342Z'
        stroke='url(#paint1_linear_11_3782)'
      />
      <path
        d='M51.4177 118.802C58.6847 181.399 103.707 250.788 151.977 273.787C200.247 296.787 233.487 264.687 226.22 202.09C218.953 139.493 173.931 70.1034 125.661 47.104C77.3903 24.1046 44.1506 56.2047 51.4177 118.802Z'
        stroke='url(#paint2_linear_11_3782)'
      />
      <path
        d='M13.1579 118.801C20.425 181.398 65.4468 250.788 113.717 273.787C161.987 296.786 195.227 264.686 187.96 202.089C180.693 139.492 135.671 70.1029 87.4008 47.1035C39.1306 24.1041 5.89088 56.2043 13.1579 118.801Z'
        stroke='url(#paint3_linear_11_3782)'
      />
    </g>
    <defs>
      <linearGradient
        id='paint0_linear_11_3782'
        x1='361.113'
        y1='189.855'
        x2='119.487'
        y2='98.7473'
        gradientUnits='userSpaceOnUse'
      >
        <stop stop-color='#171717' stop-opacity='0.47' />
        <stop offset='0.755208' stop-color='#F49D79' />
        <stop offset='1' stop-color='#FFF5CE' />
      </linearGradient>
      <linearGradient
        id='paint1_linear_11_3782'
        x1='322.854'
        y1='189.855'
        x2='81.2276'
        y2='98.7473'
        gradientUnits='userSpaceOnUse'
      >
        <stop stop-color='#171717' stop-opacity='0.47' />
        <stop offset='0.755208' stop-color='#F49D79' />
        <stop offset='1' stop-color='#FFF5CE' />
      </linearGradient>
      <linearGradient
        id='paint2_linear_11_3782'
        x1='62.7607'
        y1='152.891'
        x2='191.223'
        y2='53.2608'
        gradientUnits='userSpaceOnUse'
      >
        <stop stop-color='#171717' stop-opacity='0.06' />
        <stop offset='0.755208' stop-color='#F49D79' />
        <stop offset='1' stop-color='#FFF5CE' />
      </linearGradient>
      <linearGradient
        id='paint3_linear_11_3782'
        x1='24.501'
        y1='152.891'
        x2='152.963'
        y2='53.2603'
        gradientUnits='userSpaceOnUse'
      >
        <stop stop-color='#171717' stop-opacity='0.06' />
        <stop offset='0.755208' stop-color='#F49D79' />
        <stop offset='1' stop-color='#FFF5CE' />
      </linearGradient>
      <clipPath id='clip0_11_3782'>
        <rect width='100%' height='100%' fill='white' />
      </clipPath>
    </defs>
  </svg>
)

export default Circles
