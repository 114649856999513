import gsap from 'gsap'
import {ScrollTrigger} from 'gsap/dist/ScrollTrigger'
import React, {useEffect, useRef} from 'react'

import * as styles from './Team.module.scss'
import TeamItem from './TeamItem'

gsap.registerPlugin(ScrollTrigger)

const Team = ({items = []}) => {
  const itemsRef = useRef([])

  useEffect(() => {
    const context = gsap.context(() => {
      const items = itemsRef.current

      items.forEach((item) => {
        gsap.fromTo(
          item,
          {yPercent: '+=25', opacity: 0},
          {
            yPercent: 0,
            opacity: 1,
            duration: 1,
            ease: 'power3.out',
            scrollTrigger: {
              trigger: item,
              start: 'center, bottom',
            },
          }
        )
      })
    })

    return () => context.revert()
  }, [])

  return (
    <section className={styles.section}>
      <section className={styles.items}>
        {items.map((item, index) => {
          return (
            <div key={item._uid} className={styles.item} ref={(ref) => itemsRef.current.push(ref)}>
              <TeamItem {...item} isReversed={index % 2 === 0 ? false : true} />
            </div>
          )
        })}
      </section>
    </section>
  )
}

export default Team
