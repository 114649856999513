/* eslint-disable no-warning-comments */
/** @type {import('tailwindcss').Config} */

const {colors} = require('./tailwind-theme')

const getSpacing = () => {
  const sizes = {}
  for (let i = 0; i <= 100; i++) {
    sizes[i] = `${i * 0.25}rem`
  }
  return sizes
}

module.exports = {
  content: ['./src/**/*.jsx'],
  theme: {
    colors: {
      ...colors,
      black: '#171717',
      white: '#FFF',
      grey: {
        100: '#F9F9F9',
        200: '#F1F1F1',
        300: '#DCDCDC',
        400: '#B8B8B8',
        500: '#868686',
        800: '#33322C',
      },
      green: {
        100: '#DAF0E3',
        300: '#6FCF97',
        500: '#52B77C',
        800: '#043623',
      },
      orange: {
        300: '#FBEBD3',
        500: '#F2B860',
        800: '#EA9A22',
      },
      red: {
        100: '#FBD8D3',
        500: '#F39191',
        800: '#F56A6A',
      },
      yellow: {
        100: '#FFFBF4',
        300: '#FFFBEA',
        500: '#FFF3C7',
        800: '#FFE999',
      },
      transparent: 'transparent',
    },
    spacing: getSpacing,
    extend: {
      screens: {
        xl: '1312px',
      },
    },
  },
  plugins: [require('@tailwindcss/aspect-ratio'), require('@tailwindcss/typography')],
}
