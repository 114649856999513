import * as React from 'react'

import FooterCategoryV2 from '@/components/__deprecated/FooterV2/FooterCategoryV2'
import FooterItemV2 from '@/components/__deprecated/FooterV2/FooterItemV2'
import RichText from '@/components/__deprecated/RichText'
import SocialItem from '@/components/__deprecated/SocialItem'

import LogoFull from '../LogoFull'
import * as styles from './FooterV2.module.scss'

const FooterV2 = ({richtext, categories = [], slogan, socials = [], links = []}) => {
  return (
    <div className={styles.container}>
      <footer className={styles.footer}>
        <LogoFull className={styles.logo} />

        <article className={styles.paragraph}>
          {richtext && <RichText richtext={richtext} />}
        </article>

        <nav className={styles.nav}>
          <div className={styles.categories}>
            {categories.map((props) => (
              <div key={props._uid} className={styles.category}>
                <FooterCategoryV2 {...props} />
              </div>
            ))}
          </div>
        </nav>

        <div className={styles.divider} />

        <div className={styles.bottom}>
          {slogan && <p className={styles.slogan}>{slogan}</p>}

          <div className={styles.socials}>
            {socials.map((props) => (
              <div key={props._uid}>
                <SocialItem {...props} />
              </div>
            ))}
          </div>

          <div className={styles.links}>
            {links.map((props) => (
              <div key={props._uid} className={styles.link}>
                <FooterItemV2 {...props} />
              </div>
            ))}
          </div>
        </div>
      </footer>
    </div>
  )
}

export default FooterV2
