import * as React from 'react'

function ArrowButton({title, titleId, ...props}) {
  return (
    <svg
      width={65}
      height={84}
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      aria-labelledby={titleId}
      {...props}
    >
      {title ? <title id={titleId}>{title}</title> : null}
      <path
        d='M3.545 24.53C.305 35.247-.45 46.823 1.777 57.78c1.212 5.967 3.645 12.395 8.369 17.327 4.71 4.917 11.738 8.39 22.242 8.39 10.504 0 17.532-3.474 22.242-8.39 4.723-4.932 7.156-11.36 8.369-17.328 2.226-10.957 1.472-22.532-1.768-33.247-1.706-5.642-4.502-11.654-9.072-16.25-4.557-4.581-10.9-7.78-19.771-7.78-8.87 0-15.215 3.198-19.772 7.78-4.57 4.596-7.365 10.607-9.071 16.249z'
        stroke='#fff'
      />
      <path
        d='M32.388 62.511v-39.07m0 0c0 13.614-9.433 18.754-12.698 18.233m12.698-18.232c0 13.613 9.432 18.753 12.697 18.232'
        stroke='#fff'
      />
    </svg>
  )
}

export default ArrowButton
