import classNames from 'classnames'
import {gsap} from 'gsap'
import {ScrollTrigger} from 'gsap/dist/ScrollTrigger'
import React, {useEffect, useRef} from 'react'

import Button from '@/components/__deprecated/Button'
import Marquee from '@/components/__deprecated/MarqueeWrapper'
import Image from '@/components/Image'

import * as styles from './Showcase.module.scss'
import VectorStroke from './VectorStroke'

gsap.registerPlugin(ScrollTrigger)

const Showcase = ({
  heading,
  subheading,
  paragraph,
  buttonLabel,
  buttonLink,
  imageLarge,
  caption,
  imageMedium,
  imageSmall,
  marquee,
}) => {
  const containerRef = useRef(null)
  const headingRef = useRef(null)
  const contentRef = useRef(null)
  const largeImageRef = useRef(null)
  const mediumImageRef = useRef(null)
  const smallImageRef = useRef(null)

  useEffect(() => {
    const tweens = []

    if (headingRef.current !== null) {
      tweens.push(
        gsap.from(headingRef.current, {
          yPercent: 32,
          opacity: 0,
          scrollTrigger: {
            start: 'top top',
            trigger: containerRef.current,
          },
        })
      )
    }

    if (contentRef.current !== null) {
      tweens.push(
        gsap.from(contentRef.current, {
          opacity: 0,
          delay: 0.8,
          scrollTrigger: {
            start: 'top top',
            trigger: containerRef.current,
          },
        })
      )
    }

    if (largeImageRef.current !== null && window.matchMedia('(min-width: 1080px)').matches) {
      tweens.push(
        gsap.to(largeImageRef.current, {
          yPercent: 0,
          ease: 'none',
          scrollTrigger: {
            trigger: largeImageRef.current,
            scrub: true,
          },
        })
      )
    }

    if (mediumImageRef.current !== null) {
      tweens.push(
        gsap.to(mediumImageRef.current, {
          yPercent: 20,
          ease: 'none',
          scrollTrigger: {
            trigger: mediumImageRef.current,
            scrub: true,
          },
        })
      )
    }

    if (smallImageRef.current !== null) {
      tweens.push(
        gsap.to(smallImageRef.current, {
          yPercent: 20,
          ease: 'none',
          scrollTrigger: {
            trigger: smallImageRef.current,
            scrub: true,
          },
        })
      )
    }

    return () => {
      tweens.forEach((tween) => {
        if (tween.scrollTrigger) tween.scrollTrigger.kill()
        tween.kill()
      })
    }
  }, [])

  return (
    <section className={styles.section}>
      <div ref={containerRef} className={styles.container}>
        {heading && (
          <h2 ref={headingRef} className={styles.heading}>
            {heading}
          </h2>
        )}

        <div ref={contentRef} className={styles.content}>
          {subheading && <h3 className={styles.subheading}>{subheading}</h3>}
          {paragraph && <p className={styles.paragraph}>{paragraph}</p>}
          {buttonLabel && buttonLink && (
            <Button className={styles.button} isOutlined label={buttonLabel} link={buttonLink} />
          )}
        </div>

        {imageLarge && imageLarge.filename && (
          <div ref={largeImageRef} className={classNames(styles.imageLarge)}>
            <div className={styles.overlay}>
              <Image {...imageLarge} />
            </div>
            {caption && <p className={styles.caption}>{caption}</p>}
          </div>
        )}

        {imageMedium && imageMedium.filename && (
          <div ref={mediumImageRef} className={classNames(styles.imageMedium)}>
            <div className={styles.overlay}>
              <Image {...imageMedium} />
            </div>
          </div>
        )}

        {imageSmall && imageSmall.filename && (
          <div ref={smallImageRef} className={classNames(styles.imageSmall)}>
            <div className={styles.overlay}>
              <Image {...imageSmall} />
            </div>
          </div>
        )}
      </div>

      {marquee && <Marquee className={styles.marquee}>{marquee}</Marquee>}

      <VectorStroke className={styles.background} />
    </section>
  )
}

export default Showcase
