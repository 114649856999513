import * as React from 'react'

import CheckMark from './CheckMark'
import * as styles from './PricingCardItem.module.scss'

const Bullet = ({bullets}) =>
  bullets === 'marks' ? <CheckMark className={styles.icon} /> : <div className={styles.circle} />

const PricingCardItem = ({item, description, tag, bullets}) => {
  return (
    <>
      {item && (
        <div className={styles.container}>
          <Bullet bullets={bullets} />
          <div className={styles.content}>
            <div>
              <p className={styles.item}>{item}</p>
              {description && <p className={styles.description}>{description}</p>}
            </div>
            {tag && <p className={styles.tag}>{tag}</p>}
          </div>
        </div>
      )}
    </>
  )
}

export default PricingCardItem
