import * as React from 'react'
import {
  NODE_CODEBLOCK,
  NODE_HEADING,
  NODE_IMAGE,
  NODE_LI,
  NODE_OL,
  NODE_PARAGRAPH,
  NODE_QUOTE,
  NODE_UL,
} from 'storyblok-rich-text-react-renderer'
import resolveConfig from 'tailwindcss/resolveConfig'
import urlJoin from 'url-join'

import {LOCALES} from '@/common/config'

import rawTailwindConfig from '../../tailwind.config'

export const tailwindConfig = resolveConfig(rawTailwindConfig)

export const makeEditable = (_editable) => {
  if (!_editable) return {}

  const options = JSON.parse(_editable.replace(/^<!--#storyblok#/, '').replace(/-->$/, ''))

  return {
    'data-blok-c': JSON.stringify(options),
    'data-blok-uid': options.id + '-' + options.uid,
  }
}

export function isEmptyRichtext(richtext) {
  if (richtext === undefined || richtext === '' || !richtext.content) return true
  if (richtext.content.find((i) => i.content || i.type === 'blok') === undefined) return true
  return false
}

export const unwrapAllNodeResolvers = {
  [NODE_HEADING]: (children) => <>{children}</>,
  [NODE_CODEBLOCK]: (children) => <>{children}</>,
  [NODE_IMAGE]: (children) => <>{children}</>,
  [NODE_PARAGRAPH]: (children) => <>{children}</>,
  [NODE_QUOTE]: (children) => <>{children}</>,
  [NODE_OL]: (children) => <>{children}</>,
  [NODE_UL]: (children) => <>{children}</>,
  [NODE_LI]: (children) => <>{children}</>,
}

export function storyToLink(story) {
  return {
    linktype: 'story',
    story: {
      full_slug: story.full_slug,
    },
  }
}

export function urljoinWithTrailing(...args) {
  return urlJoin(...args, '/')
}

export function sanitizeFullSlug(fullSlug = '') {
  return fullSlug.replace('index', '')
}

export function getUnlocalizedFullSlug(fullSlug = '') {
  return sanitizeFullSlug(fullSlug)
    .split('/')
    .filter((s, i) => i !== 0 || !LOCALES.includes(s))
    .join('/')
}

export function toKebabCase(string) {
  return string
    .replace(/([a-z])([A-Z])/g, '$1-$2')
    .replace(/[\s_]+/g, '-')
    .toLowerCase()
    .replace(/[.#]/g, '')
}
