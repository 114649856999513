import React, {useState} from 'react'

import Button from '@/components/__deprecated/Button'
import RichText from '@/components/__deprecated/RichText'

import * as styles from './Pricing.module.scss'
import PricingCard from './PricingCard'
import Toggle from './Toggle'

const Pricing = ({
  heading,
  left,
  right,
  tooltip,
  cards = [],
  ctaHeading,
  ctaDescription,
  ctaButtonLabel,
  ctaButtonLink,
}) => {
  const [isActive, setIsActive] = useState(true)

  const hasCallToAction = Boolean(ctaHeading || ctaDescription || ctaButtonLabel)

  return (
    <section className={styles.section}>
      <div className={styles.container}>
        {heading && (
          <div className={styles.heading}>
            <RichText richtext={heading} />
          </div>
        )}

        {left && right && (
          <div className={styles.toggle} role='button' tabIndex={0}>
            <Toggle
              _uid={''}
              left={left}
              right={right}
              tooltip={tooltip}
              isActive={isActive}
              handleClick={() => setIsActive(!isActive)}
            />
          </div>
        )}

        <div className={styles.cards}>
          {cards.map((card) => {
            return (
              <div key={card._uid} className={styles.card}>
                <PricingCard {...card} isActive={isActive} />
              </div>
            )
          })}
        </div>

        {hasCallToAction && (
          <div className={styles.cta}>
            <div className={styles.ctaContent}>
              {ctaHeading && <p className={styles.ctaHeading}>{ctaHeading}</p>}
              {ctaDescription && <p className={styles.ctaDescription}>{ctaDescription}</p>}
            </div>
            <div className={styles.ctaButton}>
              {ctaButtonLabel && <Button label={ctaButtonLabel} link={ctaButtonLink} />}
            </div>
          </div>
        )}
      </div>
    </section>
  )
}

export default Pricing
