import classNames from 'classnames'
import * as React from 'react'

import Button from '@/components/__deprecated/Button'

import * as styles from './Onboarding.module.scss'
import Success from './Success.jsx'

export const OnboardingSuccess = ({
  onSuccessHeading,
  onSuccessDescription,
  onSuccessCallToActionLabel,
  onSuccessCallToActionLink,
}) => {
  return (
    <div className={classNames(styles.container, styles.success)}>
      <Success className={styles.icon} />

      <h1 className={styles.heading}>{onSuccessHeading}</h1>

      <p className={styles.success}>{onSuccessDescription}</p>

      <Button
        label={onSuccessCallToActionLabel}
        isSecondary
        className={styles.submit}
        link={onSuccessCallToActionLink}
      />
    </div>
  )
}
