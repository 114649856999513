/* eslint-disable no-warning-comments */

import gsap from 'gsap'
import {ScrollTrigger} from 'gsap/dist/ScrollTrigger'
import {SplitText} from 'gsap/dist/SplitText'
import React, {useEffect, useRef} from 'react'

// import GradientSmall from '../GradientSmall'
import TextQuote from '../TextQuote'
import * as styles from './Quote.module.scss'

gsap.registerPlugin(SplitText, ScrollTrigger)

const Quote = ({heading}) => {
  const headingRef = useRef()
  const topQuoteRef = useRef()
  const bottomQuoteRef = useRef()

  useEffect(() => {
    const heading = headingRef.current

    if (heading) {
      const context = gsap.context(() => {
        gsap.from([topQuoteRef.current, ...heading.children, bottomQuoteRef.current], {
          stagger: 0.2,
          yPercent: '+=60',
          opacity: 0,
          scrollTrigger: {
            trigger: heading,
            start: 'top 80%',
          },
        })
      })

      return () => context.revert()
    }
  }, [])

  return (
    <div className={styles.outer}>
      <section className={styles.section}>
        {/* <GradientSmall className={styles.gradient} /> */}

        <div className={styles.container}>
          <div className={styles.content}>
            <div className={styles.quote} ref={topQuoteRef}>
              <TextQuote />
            </div>

            {heading && (
              <h2 className={styles.heading} ref={headingRef}>
                {heading.split('  ').map((line, index) => (
                  <div key={index}>{line}</div>
                ))}
              </h2>
            )}

            <div className={styles.quote} ref={bottomQuoteRef}>
              <TextQuote />
            </div>
          </div>
        </div>
      </section>
    </div>
  )
}

export default Quote
