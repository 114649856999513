import * as React from 'react'

function TextQuote({title, titleId, ...props}) {
  return (
    <svg
      width={33}
      height={22}
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      aria-labelledby={titleId}
      {...props}
    >
      {title ? <title id={titleId}>{title}</title> : null}
      <path
        d='M24.373 15.745c0 1.007.215 1.941.647 2.804a13.32 13.32 0 001.51 2.373L25.45 22c-1.87-1.582-3.451-3.667-4.745-6.255-1.15-2.732-1.726-5.32-1.726-7.765 0-2.3.576-4.17 1.726-5.607C22 .79 23.869 0 26.314 0c1.869 0 3.45.719 4.745 2.157C32.353 3.45 33 5.033 33 6.902c0 1.87-.431 3.235-1.294 4.098a8.385 8.385 0 01-3.02 1.941l-3.02.863c-.862.287-1.293.935-1.293 1.941zm-18.98 0c0 1.007.215 1.941.646 2.804.432.863.935 1.654 1.51 2.373L6.471 22C2.157 18.261 0 13.588 0 7.98c0-2.3.575-4.17 1.725-5.607C3.02.79 4.89 0 7.333 0c1.87 0 3.451.719 4.745 2.157 1.295 1.294 1.942 2.876 1.942 4.745 0 2.013-.432 3.45-1.294 4.314a8.385 8.385 0 01-3.02 1.94l-3.02.864c-.862.287-1.294.862-1.294 1.725z'
        fill='#171717'
      />
    </svg>
  )
}

export default TextQuote
