// extracted by mini-css-extract-plugin
export var background = "Showcase-module--background--d1292";
export var button = "Showcase-module--button--214a2";
export var caption = "Showcase-module--caption--882b2";
export var container = "Showcase-module--container--8a84c";
export var content = "Showcase-module--content--2f65d";
export var gradient = "Showcase-module--gradient--a5ecb";
export var heading = "Showcase-module--heading--55f80";
export var imageLarge = "Showcase-module--imageLarge--564e6";
export var imageMedium = "Showcase-module--imageMedium--b65e6";
export var imageSmall = "Showcase-module--imageSmall--f9ee4";
export var marquee = "Showcase-module--marquee--da527";
export var overlay = "Showcase-module--overlay--de71a";
export var paragraph = "Showcase-module--paragraph--81c74";
export var section = "Showcase-module--section--58299";
export var subheading = "Showcase-module--subheading--aa6c3";