import classNames from 'classnames'
import * as React from 'react'

import Image from '@/components/Image'

import * as styles from './SliderItem.module.scss'

const SliderItem = ({label, image, hasNoLabel, hasNoImage}) => {
  return (
    <div className={styles.item}>
      {label && (
        <p className={classNames(styles.label, {[styles.hasNoLabel]: hasNoLabel})}>{label}</p>
      )}

      {image && image.filename && (
        <div className={classNames(styles.picture, {[styles.hasNoImage]: hasNoImage})}>
          <Image src={image.filename} alt={image.alt} />
        </div>
      )}
    </div>
  )
}

export default SliderItem
