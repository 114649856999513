import * as React from 'react'

function SvgOvalLine({title, titleId, ...props}) {
  return (
    <svg
      width={366}
      height={428}
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      aria-labelledby={titleId}
      {...props}
    >
      {title ? <title id={titleId}>{title}</title> : null}
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M356.569 257.988C325.707 142.808 222.955 29.717 127.145 5.3S-21.432 54.34 9.431 169.52c30.862 115.181 133.614 228.272 229.424 252.689s148.577-49.041 117.714-164.221zM126.986 4.705c96.238 24.526 199.258 138.035 230.18 253.435 30.921 115.4-21.913 189.189-118.151 164.663-96.239-24.526-199.26-138.034-230.18-253.435-30.923-115.4 21.911-189.189 118.15-164.663z'
        fill='url(#oval-line_svg__a)'
      />
      <defs>
        <linearGradient
          id='oval-line_svg__a'
          x1={106.298}
          y1={327.964}
          x2={339.914}
          y2={207.143}
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='#FF7759' stopOpacity={0} />
          <stop offset={1} stopColor='#FFF3C7' />
        </linearGradient>
      </defs>
    </svg>
  )
}

export default SvgOvalLine
