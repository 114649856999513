import * as React from 'react'

import RichText from '@/components/__deprecated/RichText'

import * as styles from './Legals.module.scss'

const Legals = ({richtext}) => {
  return (
    <div className={styles.container}>
      <section className={styles.section}>
        <RichText className={styles.richText} richtext={richtext} />
      </section>
    </div>
  )
}

export default Legals
