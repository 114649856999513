import {Link as GatsbyLink} from 'gatsby'
import * as React from 'react'

const Link = ({id, link, children, className, activeClassName, onClick}) => {
  if (!children) return null

  if (!link) {
    return <span className={className}>{children}</span>
  } else if (link.linktype === 'story') {
    const full_slug = link.story ? link.story.full_slug : link.cached_url

    let href = `/${full_slug.replace('index', '')}`

    if (link.anchor) {
      href += '#' + link.anchor
    }

    return (
      <GatsbyLink
        id={id || undefined}
        to={href}
        className={className}
        activeClassName={activeClassName}
        onClick={() => {
          // eslint-disable-next-line no-warning-comments
          // Prevent ScrollSmoother scrolling to top
          if (window.__smoother) window.__smoother.paused(true)
          if (onClick) onClick()
        }}
      >
        {children}
      </GatsbyLink>
    )
  } else {
    return (
      <a
        href={link.url}
        className={className}
        id={id || undefined}
        target={link.target}
        rel={link.target === '_blank' ? 'noopener noreferrer' : undefined}
      >
        {children}
      </a>
    )
  }
}

export default Link
