import {useLocation} from '@reach/router'
import classNames from 'classnames'
import {useState} from 'react'
import * as React from 'react'

import ButtonV2 from '@/components/__deprecated/ButtonV2'
import NavigationItem from '@/components/__deprecated/Navigation/NavigationItem'
import Link from '@/components/Link'

import background from './background.svg'
import * as styles from './Navigation.module.scss'

const Navigation = ({
  className,
  logoLink,
  nav = [],
  login = [],
  buttonLabel,
  buttonLink,
  theme,
}) => {
  const [isOpen, setIsOpen] = useState(false)
  const logoLight = '/logo-full-light.svg'
  const logoDark = '/logo-full-dark.svg'

  const location = useLocation()

  const sharedClassName = classNames({
    className,
    [styles.active]: isOpen,
    [styles.inactive]: !isOpen,
    [styles.light]: theme === 'light',
    [styles.dark]: theme === 'dark',
  })

  const handleClick = () => {
    setIsOpen((isOpen) => {
      const screenSize = window.matchMedia('(max-width: 1280px)')

      if (screenSize.matches) {
        document.documentElement.style.overflowY = isOpen ? 'visible' : 'hidden'
        return !isOpen
      }

      return !isOpen
    })
  }

  return (
    <div className={classNames(styles.outer, sharedClassName)}>
      <header className={classNames(styles.header, sharedClassName)}>
        <nav className={styles.navigation}>
          {logoLink && (
            <Link link={logoLink} className={styles.logo}>
              <img src={theme === 'light' ? logoLight : logoDark} alt='Folde Logo' />
            </Link>
          )}

          <div className={classNames(styles.container, sharedClassName)}>
            <div className={styles.items}>
              {nav.map((props) => {
                let isActive = false

                if (
                  props.link &&
                  props.link.linktype === 'story' &&
                  `/${props.link.cached_url}/` === location.pathname
                ) {
                  isActive = true
                }

                return (
                  <div key={props._uid} className={styles.item}>
                    <NavigationItem
                      {...props}
                      isActive={isActive}
                      onClick={handleClick}
                      color={theme === 'light' ? 'dark' : 'light'}
                    />
                  </div>
                )
              })}
            </div>

            {logoLink && (
              <Link link={logoLink} className={classNames(styles.logo, styles.logoInner)}>
                <img src={theme === 'light' ? logoLight : logoDark} alt='' />
              </Link>
            )}

            <div className={styles.items}>
              {login.map((props) => {
                let isActive = false

                if (
                  props.link &&
                  props.link.linktype === 'story' &&
                  `/${props.link.cached_url}/` === location.pathname
                ) {
                  isActive = true
                }

                return (
                  <div key={props._uid} className={styles.item}>
                    <NavigationItem
                      {...props}
                      isActive={isActive}
                      onClick={handleClick}
                      color={theme === 'light' ? 'dark' : 'light'}
                    />
                  </div>
                )
              })}

              {buttonLabel && buttonLink && (
                <div role='button' tabIndex={0}>
                  <ButtonV2
                    label={buttonLabel}
                    link={buttonLink}
                    buttonStyle={'outline'}
                    color={theme === 'light' ? 'dark' : 'light'}
                  />
                </div>
              )}
            </div>
          </div>

          <button
            className={classNames(styles.menu, sharedClassName)}
            onClick={handleClick}
            aria-label='Open Menu'
          >
            <div className={classNames(styles.line, sharedClassName)} />
            <div className={classNames(styles.line, sharedClassName)} />
            <div className={classNames(styles.line, sharedClassName)} />
          </button>

          {buttonLabel && buttonLink && (
            <div className={classNames(styles.buttonOuter)} role='button' tabIndex={0}>
              <ButtonV2
                label={buttonLabel}
                link={buttonLink}
                buttonStyle={'outline'}
                color={theme === 'light' ? 'dark' : 'light'}
              />
            </div>
          )}
        </nav>

        <img className={classNames(styles.background, sharedClassName)} src={background} alt='' />
      </header>
    </div>
  )
}

export default Navigation
