import * as React from 'react'

import Image from '@/components/Image'
import Link from '@/components/Link'

import * as styles from './SocialItem.module.scss'

const SocialItem = ({logo, link}) => {
  return (
    <div className={styles.container}>
      {logo && logo.filename && link && (
        <Link className={styles.link} link={link}>
          <div className={styles.picture}>
            <Image className={styles.image} src={logo.filename} alt={logo.alt} />
          </div>
        </Link>
      )}
    </div>
  )
}

export default SocialItem
