// extracted by mini-css-extract-plugin
export var button = "SectionV2-module--button--560e2";
export var column = "SectionV2-module--column--26197";
export var columnReversed = "SectionV2-module--columnReversed--adce9";
export var container = "SectionV2-module--container--3fee9";
export var content = "SectionV2-module--content--1a1bd";
export var dark = "SectionV2-module--dark--a81f0";
export var gradient = "SectionV2-module--gradient--f17ec";
export var gradientBottom = "SectionV2-module--gradientBottom--e18bf";
export var gradientTop = "SectionV2-module--gradientTop--04685";
export var gradients = "SectionV2-module--gradients--6953c";
export var heading = "SectionV2-module--heading--49bd0";
export var image = "SectionV2-module--image--72e94";
export var light = "SectionV2-module--light--0b695";
export var noGradient = "SectionV2-module--noGradient--f2826";
export var paragraph = "SectionV2-module--paragraph--911b7";
export var picture = "SectionV2-module--picture--c7ef0";
export var row = "SectionV2-module--row--ebf8a";
export var rowReversed = "SectionV2-module--rowReversed--291ec";
export var section = "SectionV2-module--section--197f2";
export var tag = "SectionV2-module--tag--ff1ac";
export var yellow = "SectionV2-module--yellow--7104e";