import {gsap} from 'gsap'
import {ScrollTrigger} from 'gsap/dist/ScrollTrigger'
import React, {useEffect, useRef} from 'react'

import MarqueeWrapper from '@/components/__deprecated/MarqueeWrapper'
import ReviewsItem from '@/components/__deprecated/Reviews/ReviewsItem'

import Circles from './Circles.jsx'
import * as styles from './Reviews.module.scss'

gsap.registerPlugin(ScrollTrigger)

const Reviews = ({heading, items = [], _uid}) => {
  const containerRef = useRef()
  const headingRef = useRef()

  useEffect(() => {
    if (headingRef.current !== null) {
      gsap.from(headingRef.current, {
        yPercent: 48,
        opacity: 0,
        scrollTrigger: {
          start: 'top center',
          trigger: containerRef.current,
        },
      })
    }
  }, [])

  return (
    <section className={styles.section} ref={containerRef}>
      <div className={styles.container}>
        <Circles className={styles.circles} />
        {heading && (
          <h2 className={styles.heading} ref={headingRef}>
            {heading}
          </h2>
        )}
      </div>

      <MarqueeWrapper scrollBack={false} className={styles.marquee}>
        <div className={styles.items}>
          {items.map((item) => {
            return (
              <div key={_uid} className={styles.item}>
                <ReviewsItem {...item} />
              </div>
            )
          })}
        </div>
      </MarqueeWrapper>
    </section>
  )
}

export default Reviews
