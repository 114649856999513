/* eslint-disable no-warning-comments */

import classNames from 'classnames'
import gsap from 'gsap'
import {ScrollTrigger} from 'gsap/dist/ScrollTrigger'
import React, {useEffect, useRef} from 'react'
import {render} from 'storyblok-rich-text-react-renderer'

import {unwrapAllNodeResolvers} from '@/common/utils'
import Buttons from '@/components/Buttons'
import Grid from '@/components/Grid'

import * as styles from './CallToAction.module.scss'

gsap.registerPlugin(ScrollTrigger)

const CallToAction = ({heading, buttons = []}) => {
  const sectionRef = useRef()

  useEffect(() => {
    const section = sectionRef.current

    if (section) {
      const context = gsap.context(() => {
        gsap.from(section, {
          yPercent: 60,
          opacity: 0,
          scrollTrigger: {
            trigger: section,
          },
        })
      })

      return () => context.revert()
    }
  }, [])

  return (
    <section className='relative py-20'>
      <div className='absolute top-1/2 bottom-0 left-0 right-0 bg-black' />
      <Grid>
        <div
          className={classNames(
            'relative col-span-full rounded-[40px] p-10 md:p-24',
            'lg:col-span-10 lg:col-start-2',
            styles.background
          )}
        >
          <div
            ref={sectionRef}
            className='col-span-full flex flex-col gap-8 text-center lg:col-span-8 lg:col-start-3'
          >
            <p className='heading-l'>{render(heading, {nodeResolvers: unwrapAllNodeResolvers})}</p>

            <Buttons buttons={buttons} />
          </div>
        </div>
      </Grid>
    </section>
  )
}

export default CallToAction
