import * as React from 'react'

import Button from '@/components/__deprecated/Button'
import Link from '@/components/Link'

import * as styles from './Onboarding.module.scss'

export const OnboardingForm = ({
  heading,
  firstname,
  lastname,
  company,
  email,
  submit,
  alternativeLabel,
  alternativeLink,
  onErrorMessage,
  handleSubmit,
  isLoading,
  isError,
}) => {
  return (
    <div className={styles.container}>
      <h1 className={styles.heading}>{heading}</h1>

      {isError && <p className={styles.error}>{onErrorMessage}</p>}

      <form className={styles.form} onSubmit={handleSubmit}>
        <div>
          <label htmlFor='firstname'>{firstname}</label>
          <input required name='firstname' id='firstname' placeholder={firstname} />
        </div>
        <div>
          <label htmlFor='lastname'>{lastname}</label>
          <input required name='lastname' id='lastname' placeholder={lastname} />
        </div>
        <div className={styles.large}>
          <label htmlFor='company'>{company}</label>
          <input required name='company' id='company' placeholder={company} />
        </div>
        <div className={styles.large}>
          <label htmlFor='email'>{email}</label>
          <input required name='email' id='email' placeholder={email} type='email' />
        </div>

        <div className={styles.large}>
          <Button
            type='submit'
            label={submit}
            isSecondary
            className={styles.submit}
            disabled={isLoading}
          />
        </div>

        <div className={[styles.large, styles.alternative].join(' ')}>
          <Link link={alternativeLink} className={styles.alternative}>
            {alternativeLabel}
          </Link>
        </div>
      </form>
    </div>
  )
}
