import classNames from 'classnames'
import * as React from 'react'
import StoryblokClient from 'storyblok-js-client'

import * as styles from './RichText.module.scss'

const Storyblok = new StoryblokClient({})

const RichText = ({richtext, className}) => {
  if (!richtext) return <div />

  return (
    <div
      className={classNames(styles.richtext, className)}
      dangerouslySetInnerHTML={{
        __html: Storyblok.richTextResolver.render(richtext).replace('<a', '<a target="_blank"'),
      }}
    />
  )
}

export default RichText
