import classNames from 'classnames'
import * as React from 'react'

import Link from '@/components/Link'

import * as styles from './ButtonV2.module.scss'

const ButtonV2 = ({
  label,
  link,
  buttonStyle,
  color,
  isDisabled,
  onClick = () => undefined,
  ...props
}) => {
  const sharedClassName = classNames(styles.button, {
    [styles.fill]: buttonStyle === 'fill',
    [styles.outline]: buttonStyle === 'outline',
    [styles.light]: color === 'light',
    [styles.dark]: color === 'dark',
    [styles.disabled]: isDisabled,
  })

  if (link) {
    return (
      <Link link={link} className={sharedClassName}>
        {label}
      </Link>
    )
  }

  return (
    <button {...props} className={sharedClassName} onClick={onClick}>
      {label}
    </button>
  )
}

export default ButtonV2
