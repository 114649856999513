// extracted by mini-css-extract-plugin
export var alternative = "Onboarding-module--alternative--b77b3";
export var aside = "Onboarding-module--aside--c1d21";
export var container = "Onboarding-module--container--85a2d";
export var error = "Onboarding-module--error--7509d";
export var form = "Onboarding-module--form--624d3";
export var heading = "Onboarding-module--heading--eedf8";
export var icon = "Onboarding-module--icon--bb792";
export var large = "Onboarding-module--large--ae09f";
export var logo = "Onboarding-module--logo--6c312";
export var main = "Onboarding-module--main--ec929";
export var section = "Onboarding-module--section--31d03";
export var submit = "Onboarding-module--submit--bb31e";
export var success = "Onboarding-module--success--bfa32";
export var wrapper = "Onboarding-module--wrapper--a4b7c";