import classNames from 'classnames'
import gsap from 'gsap'
import {SplitText} from 'gsap/dist/SplitText'
import React, {useEffect, useRef} from 'react'

import ButtonV2 from '@/components/__deprecated/ButtonV2'

import * as styles from './HeroSmall.module.scss'

gsap.registerPlugin(SplitText)

const HeroSmall = ({className, heading, paragraph, buttonLabel, buttonLink, theme}) => {
  const sharedClassName = classNames(className, {
    [styles.light]: theme === 'light',
    [styles.dark]: theme === 'dark',
  })

  const headingRef = useRef(null)
  const paragraphRef = useRef(null)
  const buttonRef = useRef(null)

  useEffect(() => {
    if (headingRef.current) {
      gsap.from(headingRef.current.children, {
        delay: 0.4,
        stagger: 0.2,
        opacity: 0,
        y: 60,
      })
    }

    if (paragraphRef.current) {
      gsap.from(paragraphRef.current, {
        delay: 1,
        opacity: 0,
        y: 80,
      })
    }

    if (buttonRef.current) {
      gsap.from(buttonRef.current, {
        delay: 1,
        opacity: 0,
        y: 80,
      })
    }
  }, [])

  return (
    <div className={classNames(styles.container, sharedClassName)}>
      <section className={styles.section}>
        {heading && (
          <h1 className={styles.heading} ref={headingRef}>
            {heading.split('  ').map((line, index) => (
              <div key={index}>{line}</div>
            ))}
          </h1>
        )}
        {paragraph && (
          <p className={styles.paragraph} ref={paragraphRef}>
            {paragraph}
          </p>
        )}
        {buttonLabel && buttonLink && (
          <div className={styles.button} ref={buttonRef}>
            <ButtonV2
              label={buttonLabel}
              link={buttonLink}
              buttonStyle={theme === 'light' ? 'fill' : 'outline'}
              color={theme === 'light' ? 'dark' : 'light'}
            />
          </div>
        )}
      </section>
    </div>
  )
}

export default HeroSmall
