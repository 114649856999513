import * as React from 'react'

import FooterItemV2 from '@/components/__deprecated/FooterV2/FooterItemV2'

import * as styles from './FooterCategoryV2.module.scss'

const FooterCategoryV2 = ({links = []}) => {
  return (
    <div className={styles.container}>
      {links.map((props) => (
        <FooterItemV2 key={props._uid} {...props} />
      ))}
    </div>
  )
}

export default FooterCategoryV2
