import * as React from 'react'

import PricingCardItem from '../PricingCardItem'
import * as styles from './PricingCardCategory.module.scss'

const PricingCardCategory = ({heading, items = [], bullets}) => {
  return (
    <div>
      {heading && <p className={styles.heading}>{heading}</p>}
      <div className={styles.items}>
        {items.map((item) => {
          return (
            <div key={item._uid}>
              <PricingCardItem {...item} bullets={bullets} />
            </div>
          )
        })}
      </div>
    </div>
  )
}

export default PricingCardCategory
