import classNames from 'classnames'
import * as React from 'react'

import Link from '@/components/Link'

import * as styles from './Button.module.scss'

const Button = ({className, label, onClick = () => undefined, ...props}) => {
  const sharedClassName = classNames(className, styles.button, {
    [styles.isSecondary]: props.isSecondary,
    [styles.isOutlined]: props.isOutlined,
  })

  if (props.link) {
    return (
      <Link link={props.link} className={sharedClassName}>
        {label}
      </Link>
    )
  }

  return (
    <button {...props} className={sharedClassName} onClick={onClick}>
      {label}
    </button>
  )
}

export default Button
