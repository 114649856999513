// extracted by mini-css-extract-plugin
export var active = "Toggle-module--active--39e21";
export var container = "Toggle-module--container--4aafc";
export var content = "Toggle-module--content--a78b7";
export var hover = "Toggle-module--hover--97516";
export var left = "Toggle-module--left--ec5df";
export var mask = "Toggle-module--mask--0a0a0";
export var right = "Toggle-module--right--f1427";
export var svg = "Toggle-module--svg--550bf";
export var tag = "Toggle-module--tag--53a58";
export var tagContainer = "Toggle-module--tagContainer--676a8";
export var toggle = "Toggle-module--toggle--1cb7e";
export var tooltip = "Toggle-module--tooltip--0a353";