// eslint-disable-next-line no-warning-comments
/* eslint-disable react-hooks/exhaustive-deps */

import classNames from 'classnames'
import gsap from 'gsap'
import React, {useEffect, useMemo, useRef} from 'react'

import IconPlus from '../IconPlus'
import * as styles from './ManifestoItem.module.scss'

const ManifestoItem = ({index, heading, paragraph, isActive, handleClick = () => undefined}) => {
  const contentRef = useRef(null)
  const timeline = useMemo(() => gsap.timeline(), [])

  const sharedClassName = classNames({
    [styles.isActive]: isActive,
  })

  useEffect(() => {
    if (contentRef.current) {
      timeline.from(contentRef.current, {
        height: 0,
        duration: 0.4,
        opacity: 0,
      })
    }
  }, [])

  useEffect(() => {
    isActive ? timeline.play() : timeline.timeScale(2).reverse()
  }, [isActive])

  return (
    <div onClick={handleClick} role='button' onKeyPress={handleClick} tabIndex={0}>
      <article className={classNames(styles.article, sharedClassName)}>
        {index && <p className={styles.index}>{index}</p>}
        {heading && <h3 className={classNames(styles.heading, sharedClassName)}>{heading}</h3>}

        {paragraph && (
          <p className={classNames(styles.paragraph, sharedClassName)} ref={contentRef}>
            {paragraph}
          </p>
        )}

        <div className={classNames(styles.button, sharedClassName)} aria-label='Show Content'>
          <IconPlus />
        </div>
      </article>
    </div>
  )
}

export default ManifestoItem
