import classNames from 'classnames'
import gsap from 'gsap'
import {SplitText} from 'gsap/dist/SplitText'
import React, {useEffect, useRef} from 'react'

import ButtonV2 from '@/components/__deprecated/ButtonV2'
import Image from '@/components/Image'
import useIsPageReady from '@/hooks/useIsPageReady'

import * as styles from './HeroLarge.module.scss'
import IlluDark from './illu-dark.jsx'
import IlluLight from './IlluLight.jsx'

gsap.registerPlugin(SplitText)

const HeroLarge = ({
  className,
  heading,
  paragraph,
  buttonLabel,
  buttonLink,
  imageLeft,
  imageRight,
  theme,
}) => {
  const {isPageReady} = useIsPageReady()

  const containerStyles = classNames(className, {
    [styles.light]: theme === 'light',
    [styles.dark]: theme === 'dark',
  })

  const headingRef = useRef(null)
  const paragraphRef = useRef(null)
  const buttonRef = useRef(null)

  useEffect(() => {
    if (isPageReady) {
      if (headingRef.current) {
        gsap.to(headingRef.current.children, {
          stagger: 0.2,
          opacity: 1,
          y: 0,
        })
      }

      gsap.to([paragraphRef.current, buttonRef.current], {
        delay: 0.4,
        opacity: 1,
        y: 0,
      })
    }
  }, [isPageReady])

  return (
    <div className={classNames(styles.container, containerStyles)}>
      <section className={styles.section}>
        {heading && (
          <h1 className={styles.heading} ref={headingRef}>
            {heading.split('  ').map((line, index) => (
              <div key={index} className='translate-y-15 opacity-0'>
                {line}
              </div>
            ))}
          </h1>
        )}
        {paragraph && (
          <p
            className={classNames(styles.paragraph, 'translate-y-20 opacity-0')}
            ref={paragraphRef}
          >
            {paragraph}
          </p>
        )}
        {buttonLabel && buttonLink && (
          <div className={classNames(styles.button, 'translate-y-20 opacity-0')} ref={buttonRef}>
            <ButtonV2
              label={buttonLabel}
              link={buttonLink}
              buttonStyle={theme === 'light' ? 'fill' : 'outline'}
              color={theme === 'light' ? 'dark' : 'light'}
            />
          </div>
        )}
      </section>

      <div className={styles.images}>
        {imageLeft && imageLeft.filename && (
          <div className={styles.image}>
            <Image {...imageLeft} sizes={{md: 50}} />
          </div>
        )}

        {imageRight && imageRight.filename && (
          <div className={styles.image}>
            <Image {...imageRight} sizes={{md: 50}} />
          </div>
        )}
      </div>

      <div className={styles.background}>
        {theme === 'light' && <IlluLight className={styles.backgroundLight} />}
        {theme === 'dark' && <IlluDark className={styles.backgroundDark} />}
      </div>
    </div>
  )
}

export default HeroLarge
