import {gsap} from 'gsap'
import {ScrollSmoother} from 'gsap/ScrollSmoother'
import {ScrollTrigger} from 'gsap/ScrollTrigger'
import * as React from 'react'

gsap.registerPlugin(ScrollTrigger, ScrollSmoother)

export const ScrollSmootherContext = ({children}) => {
  React.useEffect(() => {
    let timeoutId
    const onEvent = () => {
      if (window.__smoother) {
        window.__smoother.paused(false)
        const hash = window.location.href.split('#')[1]
        if (hash) {
          timeoutId = window.setTimeout(() => {
            window.__smoother.scrollTo(`#${hash}`, true, 'top top')
          }, 100)
        }
      }
    }

    window.__smoother = ScrollSmoother.create()
    window.addEventListener('layoutReady', onEvent)

    return () => {
      window.clearTimeout(timeoutId)
      window.removeEventListener('layoutReady', onEvent)
    }
  }, [])

  return <>{children}</>
}

export const ScrollSmootherWrapper = ({children}) => (
  <div id='smooth-wrapper'>
    <div id='smooth-content'>{children}</div>
  </div>
)
