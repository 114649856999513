import classNames from 'classnames'
import * as React from 'react'

import ButtonV2 from '@/components/__deprecated/ButtonV2'

import * as styles from './PricingCard.module.scss'
import PricingCardCategory from './PricingCardCategory'

const PricingCard = ({
  title,
  monthlyPrice,
  annualPrice,
  buttonLabel,
  buttonLink,
  items = [],
  description,
  descriptionDetails,
  isActive,
}) => {
  return (
    <article className={styles.card}>
      <div className={styles.container}>
        <div className={styles.header}>
          {title && <p className={styles.title}>{title}</p>}
          {monthlyPrice && annualPrice && (
            <p className={styles.price}>{isActive ? annualPrice : monthlyPrice}</p>
          )}
          {buttonLabel && buttonLink && (
            <div className={styles.button} role='button' tabIndex={0}>
              <ButtonV2 label={buttonLabel} link={buttonLink} buttonStyle={'fill'} color={'dark'} />
            </div>
          )}
          {items[0] && (
            <div className={classNames(styles.item, styles.isFirst)}>
              <PricingCardCategory {...items[0]} />
            </div>
          )}
        </div>

        <div className={styles.items}>
          {items.slice(1).map((item) => {
            return (
              <div key={item._uid} className={styles.item}>
                <PricingCardCategory {...item} />
              </div>
            )
          })}
        </div>
      </div>

      <div className={styles.divider} />

      <div className={styles.container}>
        <div className={styles.footer}>
          <div>
            {description && <p className={styles.description}>{description}</p>}
            {descriptionDetails && (
              <p className={styles.descriptionDetails}>{descriptionDetails}</p>
            )}
          </div>

          {buttonLabel && buttonLink && (
            <div className={styles.button} role='button' tabIndex={0}>
              <ButtonV2 label={buttonLabel} link={buttonLink} buttonStyle={'fill'} color={'dark'} />
            </div>
          )}
        </div>
      </div>
    </article>
  )
}

export default PricingCard
