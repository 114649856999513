import gsap from 'gsap'
import {ScrollTrigger} from 'gsap/dist/ScrollTrigger'
import React, {useEffect, useRef, useState} from 'react'

import * as styles from './Accordion.module.scss'
import AccordionItem from './AccordionItem'

gsap.registerPlugin(ScrollTrigger)

const Accordion = ({heading, items = []}) => {
  const [active, setActive] = useState()
  const headingRef = useRef()
  const itemsRef = useRef([])

  useEffect(() => {
    const heading = headingRef.current
    const items = itemsRef.current

    if (heading) {
      gsap.from(heading, {
        scrollTrigger: {
          trigger: heading,
          start: 'top 80%',
          end: 'center center',
          once: true,
        },
        yPercent: 60,
        opacity: 0,
      })
    }

    if (items) {
      items.forEach((item) => {
        gsap.from(item, {
          scrollTrigger: {
            trigger: item,
            start: 'top 80%',
            end: 'center center',
            once: true,
          },
          yPercent: 60,
          opacity: 0,
        })
      })
    }
  }, [])

  return (
    <section className={styles.section}>
      <h2 className={styles.heading} ref={headingRef}>
        {heading}
      </h2>

      <div className={styles.items}>
        {items.map((item, index) => {
          const isActive = index === active
          return (
            <div key={item._uid} className={styles.item} ref={(ref) => itemsRef.current.push(ref)}>
              <AccordionItem {...item} isActive={isActive} handleClick={() => setActive(index)} />
            </div>
          )
        })}
      </div>
    </section>
  )
}

export default Accordion
