import * as React from 'react'

const StarFull = () => (
  <svg width='16' height='16' fill='none' xmlns='http://www.w3.org/2000/svg'>
    <path
      d='M7.029 2.325a.5.5 0 0 1 .942 0l1.216 3.42 3.53.162a.5.5 0 0 1 .298.883L10.23 9.119l.94 3.567a.5.5 0 0 1-.768.538L7.5 11.204l-2.902 2.02a.5.5 0 0 1-.769-.538l.941-3.567-2.785-2.33a.5.5 0 0 1 .298-.882l3.53-.163 1.216-3.42Z'
      fill='#F2B860'
    />
  </svg>
)

export default StarFull
