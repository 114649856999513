import * as React from 'react'

const IlluLight = ({className}) => (
  <svg
    className={className}
    preserveAspectRatio='none'
    viewBox='0 0 781 280'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
  >
    <path
      opacity='0.2'
      d='M47.9847 490.174C103.773 394.804 215.923 248.791 346.415 141.528M346.415 141.528C483.749 28.6403 645.577 -38.9145 779.222 38.2451C734.241 101.345 584.421 187.84 346.415 141.528ZM346.415 141.528C251.97 123.151 122.561 71.4565 1.38604 1.49589'
      stroke='#171717'
      stroke-width='2'
    />
  </svg>
)

export default IlluLight
