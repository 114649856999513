import classNames from 'classnames'
import gsap from 'gsap'
import React, {useEffect, useRef, useState} from 'react'
import Marquee from 'react-fast-marquee'

import TestimonialsItem from '@/components/__deprecated/Testimonials/TestimonialsItem'

import ArrowButton from '../ArrowButton'
import * as styles from './Testimonials.module.scss'

gsap.registerPlugin()

const Testimonials = ({heading, items = [], transparentBackground, layout}) => {
  const [active, setActive] = useState(0)
  const [direction, setDirection] = useState()

  const itemRef = useRef()

  const handleClick = (direction) => {
    direction === 'prev' && setActive((prev) => (prev > 0 ? prev - 1 : items.length - 1))
    direction === 'next' && setActive((prev) => (prev < items.length - 1 ? prev + 1 : 0))
    setDirection(direction)
  }

  useEffect(() => {
    gsap.from(itemRef.current, {
      delay: 0.3,
      opacity: 0,
      y: direction === 'prev' ? 80 : -80,
    })
  }, [active, direction])

  return (
    <div
      className={classNames(styles.container, {
        [styles.transparentBackground]: transparentBackground,
      })}
    >
      <section className={styles.section}>
        {heading ? (
          <div className={styles.marquee}>
            <Marquee className={styles.marqueeText} direction='right' speed={1} gradient={false}>
              {`${heading} ${heading} ${heading} `}
            </Marquee>
          </div>
        ) : null}

        <div className={styles.items} ref={itemRef}>
          {items.map((item, index) => {
            return (
              <div
                key={item._uid}
                className={classNames(styles.item, {[styles.isActive]: index === active})}
              >
                <TestimonialsItem {...item} layout={layout} />
              </div>
            )
          })}
        </div>

        <div className={styles.controls}>
          <button onClick={() => handleClick('prev')} aria-label='Previous Slide'>
            <ArrowButton className={styles.iconPrev} />
          </button>

          <div className={styles.index}>
            <p>{active < 10 ? `0${active + 1}` : active + 1}</p>
            <div className={styles.divider} />
            <p>{items.length < 10 ? `0${items.length}` : items.length}</p>
          </div>

          <button onClick={() => handleClick('next')} aria-label='Next Slide'>
            <ArrowButton className={styles.iconNext} />
          </button>
        </div>
      </section>
    </div>
  )
}

export default Testimonials
