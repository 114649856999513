// extracted by mini-css-extract-plugin
export var button = "Slider-module--button--e83ed";
export var container = "Slider-module--container--a04d6";
export var controls = "Slider-module--controls--f873c";
export var divider = "Slider-module--divider--7ea53";
export var heading = "Slider-module--heading--dfc3a";
export var iconNext = "Slider-module--iconNext--86515";
export var iconPrev = "Slider-module--iconPrev--53b6e";
export var imageItem = "Slider-module--imageItem--994e5";
export var imageItems = "Slider-module--imageItems--0e678";
export var index = "Slider-module--index--58861";
export var isActive = "Slider-module--isActive--9f8d5";
export var label = "Slider-module--label--edd07";
export var paragraph = "Slider-module--paragraph--a768e";
export var section = "Slider-module--section--5be32";
export var textItem = "Slider-module--textItem--90618";
export var textItems = "Slider-module--textItems--c3936";