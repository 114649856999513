// extracted by mini-css-extract-plugin
export var card = "Pricing-module--card--c4e14";
export var cards = "Pricing-module--cards--87743";
export var container = "Pricing-module--container--9a191";
export var cta = "Pricing-module--cta--e7682";
export var ctaButton = "Pricing-module--ctaButton--0705a";
export var ctaContent = "Pricing-module--ctaContent--84cd9";
export var ctaDescription = "Pricing-module--ctaDescription--20666";
export var ctaHeading = "Pricing-module--ctaHeading--18036";
export var heading = "Pricing-module--heading--edac3";
export var section = "Pricing-module--section--0b3f7";
export var toggle = "Pricing-module--toggle--1a92d";