import classNames from 'classnames'
import * as React from 'react'

import Link from '@/components/Link'

import line from './line.svg'
import * as styles from './NavigationItem.module.scss'

const NavigationItem = ({className, label, link, color, isActive, onClick = () => undefined}) => {
  const sharedClassName = classNames(className, {
    [styles.light]: color === 'light',
    [styles.dark]: color === 'dark',
  })

  return (
    <div
      className={classNames(styles.container, sharedClassName)}
      onClick={onClick}
      onKeyDown={onClick}
      role='link'
      tabIndex={0}
    >
      <Link link={link} className={classNames(styles.link, sharedClassName)}>
        {label}
      </Link>
      <img className={classNames(styles.svg, {[styles.active]: isActive})} src={line} alt='' />
    </div>
  )
}

export default NavigationItem
