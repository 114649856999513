const IS_DEVELOPMENT = [process.env.NODE_ENV, process.env.GATSBY_ENV].includes('development')

const STORYBLOK_TOKEN = '5MtwNS3BqrAZl5aKefC2GQtt'
const DEFAULT_LOCALE = 'fr'
const RESOLVE_RELATIONS = [
  'global-component.reference',
  'blog-post.category',
  'blog-post.author',
  'blog-post.tags',
]

const getPagePath = (full_slug) => {
  return full_slug === 'index' || full_slug === '/'
    ? '/'
    : `/${full_slug.replace('/index', '').replace(/^\/+/, '').replace(/\/+$/, '')}/`
        .replace('/http', 'http')
        .replace('*/', '*')
        .replace(':splat/', ':splat')
}

const getLayoutUuid = (story) =>
  story?.content?.layout || story?.content?.category?.content?.articlesLayout

// eslint-disable-next-line no-warning-comments
// DO NOT TOUCH - Populate by prebuild.js
const LOCALES = []
const SITE_NAME = 'Folde'
const SITE_URL = 'https://www.folde.eu'

module.exports = {
  DEFAULT_LOCALE,
  IS_DEVELOPMENT,
  LOCALES,
  RESOLVE_RELATIONS,
  SITE_NAME,
  SITE_URL,
  STORYBLOK_TOKEN,
  utils: {
    getLayoutUuid,
    getPagePath,
  },
}
