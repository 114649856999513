import {m} from 'framer-motion'
import * as React from 'react'

import DynamicComponent from '@/components/DynamicComponent'
import {TRANSITION_ENTER, TRANSITION_EXIT} from '@/components/PageTransition'

export const TRANSITION_DURATION = 0.4

const Placeholder = () => (
  <div className='color-[#1b243f] bg-[#f7f8f9] py-50 px-2 text-center text-[2.6rem] font-bold uppercase'>
    Content 📝
  </div>
)
const Layout = ({children, story = {}}) => {
  const {content, isPreview} = story.layout || {}
  const {top = [], bottom = []} = content || {}

  React.useEffect(() => {
    window.dispatchEvent(new Event('layoutReady'))
  }, [])

  return (
    <m.div
      initial={{y: 40}}
      animate={{y: 0}}
      exit={{y: -40}}
      transition={{
        type: 'tween',
        ease: 'easeOut',
        duration: Math.min(TRANSITION_EXIT, TRANSITION_ENTER),
      }}
    >
      {top.map((component) => {
        return (
          <DynamicComponent
            key={component._uid}
            story={story}
            {...component}
            _editable={isPreview ? component._editable : undefined}
          />
        )
      })}

      <main>{isPreview ? <Placeholder /> : children}</main>

      {bottom.map((component) => (
        <DynamicComponent
          key={component._uid}
          story={story}
          {...component}
          _editable={isPreview ? component._editable : undefined}
        />
      ))}
    </m.div>
  )
}

export default Layout
