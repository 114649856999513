import React, {useState} from 'react'

import Testimonials from '@/components/__deprecated/Testimonials'

import LogoFull from '../LogoFull'
import * as styles from './Onboarding.module.scss'
import {OnboardingForm} from './OnboardingForm'
import {OnboardingSuccess} from './OnboardingSuccess'

const Onboarding = (props) => {
  const [isLoading, setIsLoading] = useState(false)
  const [isError, setIsError] = useState(false)
  const [isSuccess, setIsSuccess] = useState(false)

  async function handleSubmit(e) {
    e.preventDefault()

    const firstname = e.target.firstname.value
    const lastname = e.target.lastname.value
    const company = e.target.company.value
    const email = e.target.email.value

    setIsLoading(true)
    setIsError(false)
    setIsSuccess(false)

    try {
      if (!props.formURL) {
        throw Error('formURL missing')
      }

      await fetch(props.formURL, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
        },
        body: JSON.stringify({firstname, lastname, company, email}),
      })
      setIsSuccess(true)
    } catch (error) {
      setIsError(true)
    } finally {
      setIsLoading(false)
    }
  }

  return (
    <section className={styles.section}>
      <div className={styles.main}>
        <div className={styles.wrapper}>
          <LogoFull className={styles.logo} />

          {isSuccess ? (
            <OnboardingSuccess {...props} />
          ) : (
            <OnboardingForm
              {...props}
              handleSubmit={handleSubmit}
              isLoading={isLoading}
              isError={isError}
            />
          )}
        </div>
      </div>

      <aside className={styles.aside}>
        <Testimonials items={props.testimonials} transparentBackground layout='alternative' />
      </aside>
    </section>
  )
}

export default Onboarding
