import * as React from 'react'

import * as styles from './ReviewsItem.module.scss'
import StarEmpty from './StarEmpty'
import StarFull from './StarFull'

const ReviewsItem = ({review, author, rating}) => {
  const handleRating = () => {
    const ratingCount = rating && 5 - rating

    const FullStars = Array.from({length: rating || 0}, (_, index) => {
      return <StarFull key={index} />
    })

    const EmptyStars = Array.from({length: ratingCount || 0}, (_, index) => {
      return <StarEmpty key={index} />
    })

    if (rating && rating >= 0 && rating <= 5) {
      return (
        <>
          {FullStars}
          {EmptyStars}
        </>
      )
    }
  }

  return (
    <article className={styles.card}>
      {review && <p className={styles.review}>{review}</p>}
      <div className={styles.divider} />
      <div className={styles.container}>
        {author && <p className={styles.author}>{author}</p>}
        <div className={styles.rating}>{handleRating()}</div>
      </div>
    </article>
  )
}

export default ReviewsItem
