import * as React from 'react'

import LinksItem from '@/components/__deprecated/Links/LinksItem'

import * as styles from './Links.module.scss'
import background from './shape-background-folde-logo-background.svg'

const Links = ({heading, paragraph, items = []}) => {
  return (
    <section className={styles.section}>
      <div className={styles.content}>
        {heading && <h3 className={styles.heading}>{heading}</h3>}
        {paragraph && <p className={styles.paragraph}>{paragraph}</p>}

        <div className={styles.items}>
          {items.map((item) => {
            return (
              <div key={item._uid} className={styles.item}>
                <LinksItem {...item} />
              </div>
            )
          })}
        </div>

        <img className={styles.background} src={background} alt='' />
      </div>
    </section>
  )
}

export default Links
