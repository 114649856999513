/* eslint-disable no-warning-comments */
// @ts-check

/**
 *
 * @typedef {{quality?: number}} Filters
 * @typedef {{sm?: number, md?: number, lg?: number, unit?: 'px' | 'vw', exclude?: string | string[]}} Sizes
 *
 * @typedef Image
 * @property {string} [props.src]
 * @property {string} [props.filename]
 * @property {string} [props.alt]
 * @property {'lazy' | 'eager'} [props.loading]
 * @property {Sizes} [props.sizes]
 * @property {Filters} [props.filters]
 * @property {boolean} [props.isFullWidth]
 * @property {string} [props.width]
 * @property {string} [props.height]
 * @property {string} [props.ratio]
 * @property {boolean} [props.fitIn]
 * @property {string} [props.className]
 */

import classNames from 'classnames'
import * as React from 'react'

import {tailwindConfig} from '@/common/utils'

function getExtension(url) {
  return url.split(/[#?]/)[0].split('.').pop().trim()
}

const breakpoints = {
  md: tailwindConfig.theme.screens['md'].replace('px', ''),
  lg: tailwindConfig.theme.screens['lg'].replace('px', ''),
  max: tailwindConfig.theme.screens['xl'].replace('px', ''),
}

const originalSrcRegex = new RegExp(/\/\/a.storyblok.com/)

/**
 *
 * @param {string} src
 * @returns {{width: number; height: number} | undefined}
 */
function getDimensions(src) {
  if (isStoryblokImage(src)) {
    const match = src.match(/(\d+x\d+)/g)
    if (match && match[0]) {
      const [w, h] = match[0].split('x')
      return {width: parseInt(w), height: parseInt(h)}
    }
  }

  return undefined
}

/**
 *
 * @param {string} src
 * @returns {boolean}
 */
function isStoryblokImage(src) {
  return !!src && originalSrcRegex.test(src)
}

/**
 *
 * @param {string} src
 * @returns {boolean}
 */
function isSvg(src) {
  return !!src && /\.svg$/.test(src)
}

/**
 *
 * @param {string} src
 * @returns {string}
 */
function withCustomCDN(src) {
  return src.replace('a.storyblok.com', 'pelostudio-storyblok-assets.b-cdn.net')
}

/**
 *
 * @param {string} src
 * @param {Filters} filters
 * @returns {string}
 */
function getFilters(src, filters) {
  const options = Object.values({
    baseUrl: '/filters',
    quality: filters?.quality ? `quality(${filters?.quality})` : undefined,
  }).filter((v) => !!v)

  return options.length > 1 ? options.join(':') : undefined
}

/**
 *
 * @param {number} width
 * @param {string} ratio
 * @returns {number | undefined}
 */
function getHeight(width, ratio) {
  if (width && ratio) {
    const [w, h] = ratio.split(':').map((v) => parseInt(v))
    return Math.round((width * h) / w)
  }

  return undefined
}

/**
 *
 * @param {string} src
 * @param {number} width
 * @param {Filters} filters
 * @param {string} ratio
 * @param {boolean} fitIn
 * @returns {string}
 */
function getSrc(src, width, filters, ratio, fitIn) {
  if (!src) return ''

  if (!isStoryblokImage(src)) {
    return src
  } else if (isSvg(src)) {
    return withCustomCDN(src)
  } else {
    const height = getHeight(width, ratio) || 0
    const options = {
      fitIn: fitIn ? '/fit-in' : undefined,
      width: width ? `/${width}x${height}` : '',
      smart: '/smart',
      filters: getFilters(src, filters),
    }

    const fullUrl = [withCustomCDN(src), '/m', ...Object.values(options)]
      .filter((v) => !!v)
      .join('')

    return fullUrl
  }
}

/**
 *
 * @param {string} src
 * @param {Filters} filters
 * @param {string} ratio
 * @param {boolean} fitIn
 * @returns {string}
 */
function getSet(src, filters, ratio, fitIn) {
  if (
    !src ||
    !isStoryblokImage(src) ||
    isSvg(src) ||
    /(?:https?|ftp):\/\/[\S]*\.(?:webp)(?:\?\S+=\S*(?:&\S+=\S*)*)?/gi.test(src)
  )
    return undefined

  let widths = [256, 384, 640, 750, 828, 1080, 1200, 1920, 2048, 3840]

  const dimensions = getDimensions(src)
  if (dimensions) {
    widths = widths
      .concat([dimensions.width])
      .sort((a, b) => a - b)
      .filter((w) => w <= dimensions.width)
  }

  const set = widths.map((width) => `${getSrc(src, width, filters, ratio, fitIn)} ${width}w`)

  return set.join()
}

/**
 *
 * @param {string} src
 * @param {Sizes} options
 * @returns {string}
 */
function getSizes(src, options) {
  if (!options || !src || isSvg(src)) return undefined

  if (typeof options === 'string') {
    return options
  }

  const base = 100
  const {sm, md, lg, unit = 'vw'} = options

  const definitions = {
    max: breakpoints.max * ((lg || md || sm || base) / 100),
    lg: lg || md || sm || base,
    md: md || sm || base,
    sm: sm || base,
  }

  const sizes = [
    unit === 'px' ? undefined : `(min-width: ${breakpoints.max}px) ${definitions.max}px`,
    `(min-width: ${breakpoints.lg}px) ${definitions.lg}${unit}`,
    `(min-width: ${breakpoints.md}px) ${definitions.md}${unit}`,
    `${definitions.sm}${unit}`,
  ]

  return sizes.filter(Boolean).join(', ')
}

/**
 *
 * @param {...Image} props
 * @returns {JSX.Element | null}
 */
function Image({
  filename,
  src,
  sizes = {sm: 100},
  filters,
  isFullWidth,
  alt = '',
  loading = 'lazy',
  width,
  height,
  ratio,
  fitIn,
  className,
}) {
  src = filename || src

  if (src) {
    const extension = getExtension(src)
    const excluded =
      typeof sizes === 'string'
        ? false
        : Array.isArray(sizes.exclude)
        ? sizes.exclude.includes(extension)
        : sizes.exclude === extension

    if (excluded) return null

    const newSrc = getSrc(src, undefined, filters, ratio, fitIn)
    const srcSet = getSet(src, filters, ratio, fitIn)
    const newSizes = isFullWidth ? '100vw' : getSizes(src, sizes)
    const dimensions = getDimensions(src)

    if (dimensions && ratio) {
      dimensions.height = getHeight(dimensions.width, ratio)
    }

    return (
      <img
        src={newSrc}
        srcSet={srcSet}
        sizes={newSizes}
        alt={alt || ''}
        loading={loading}
        width={width || dimensions?.width}
        height={height || dimensions?.height}
        className={classNames(
          {'object-cover': !!ratio && !fitIn, 'object-contain': !!ratio && fitIn},
          className
        )}
      />
    )
  }

  return null
}

export default Image
