import classNames from 'classnames'
import * as React from 'react'

const Grid = ({as = 'div', className, children, ...props}) => {
  const Tag = as
  return (
    <Tag
      {...props}
      className={classNames(
        'grid w-full grid-cols-4 gap-x-2 px-4',
        'md:grid-cols-6 md:gap-x-4 md:px-12',
        'lg:mx-auto lg:max-w-screen-xl lg:grid-cols-12',
        className
      )}
    >
      {children}
    </Tag>
  )
}

export default Grid
