import * as React from 'react'

const IlluDark = ({className}) => (
  <svg
    className={className}
    preserveAspectRatio='none'
    width='100%'
    height='100%'
    viewBox='0 0 611 665'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
  >
    <path
      d='M912.868 299.292C652.214 338.399 56.9772 323.951 3.08369 -21.8033M1.00018 -35.1701C126.589 -42.5755 428.404 98.9104 516.606 664.771'
      stroke='url(#paint0_linear_2_1596)'
      stroke-width='0.72556'
    />
    <defs>
      <linearGradient
        id='paint0_linear_2_1596'
        x1='421.5'
        y1='102.5'
        x2='297.336'
        y2='569.577'
        gradientUnits='userSpaceOnUse'
      >
        <stop stop-color='#FFF3C7' />
        <stop offset='1' stop-color='#FF7759' stop-opacity='0' />
      </linearGradient>
    </defs>
  </svg>
)

export default IlluDark
