import * as React from 'react'

import Link from '@/components/Link'

import * as styles from './FooterItemV2.module.scss'

const FooterItemV2 = ({label, link}) => {
  return (
    <Link link={link} className={styles.label}>
      {label}
    </Link>
  )
}

export default FooterItemV2
