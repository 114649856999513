import classNames from 'classnames'
import React, {useState} from 'react'

import * as styles from './Toggle.module.scss'
import Tooltip from './Tooltip'

const Toggle = ({className, left, right, tooltip, isActive, handleClick = () => undefined}) => {
  const [isHovered, setIsHovered] = useState(false)
  const sharedClassName = classNames({className, [styles.active]: isActive})

  return (
    <div className={styles.container}>
      {left && right && (
        <button className={classNames(styles.toggle, sharedClassName)} onClick={handleClick}>
          <div className={styles.content}>
            <div className={classNames(styles.left, sharedClassName)}>{left}</div>
            <div
              onMouseEnter={() => setIsHovered(true)}
              onMouseLeave={() => setIsHovered(false)}
              className={classNames(styles.right, sharedClassName)}
            >
              {right}
            </div>
          </div>
          <div className={classNames(styles.mask, sharedClassName)} />
        </button>
      )}

      {tooltip && (
        <div className={classNames(styles.tagContainer, {[styles.hover]: isHovered})}>
          <div className={classNames(styles.tag, sharedClassName)}>
            <Tooltip className={styles.svg} />
            <p className={classNames(styles.tooltip, sharedClassName)}>{tooltip}</p>
          </div>
        </div>
      )}
    </div>
  )
}

export default Toggle
