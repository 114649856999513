import * as React from 'react'

function Tooltip({...props}) {
  return (
    <svg
      {...props}
      width='8'
      height='4'
      viewBox='0 0 8 4'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M0 3.75L2.68299 1.40238C3.43704 0.742587 4.56296 0.742588 5.31701 1.40238L8 3.75H0Z'
        fill='#171717'
      />
    </svg>
  )
}

export default Tooltip
