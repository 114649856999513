// extracted by mini-css-extract-plugin
export var bottom = "FooterV2-module--bottom--d7d91";
export var categories = "FooterV2-module--categories--ff4af";
export var category = "FooterV2-module--category--496bb";
export var container = "FooterV2-module--container--e02e0";
export var divider = "FooterV2-module--divider--5afd6";
export var footer = "FooterV2-module--footer--c6b7b";
export var link = "FooterV2-module--link--a121d";
export var links = "FooterV2-module--links--80636";
export var logo = "FooterV2-module--logo--ed4dc";
export var nav = "FooterV2-module--nav--73a8d";
export var paragraph = "FooterV2-module--paragraph--91568";
export var slogan = "FooterV2-module--slogan--50c94";
export var socials = "FooterV2-module--socials--40c4f";