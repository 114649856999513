// eslint-disable-next-line no-warning-comments
/* eslint-disable react-hooks/exhaustive-deps */

import classNames from 'classnames'
import gsap from 'gsap'
import React, {useEffect, useMemo, useRef} from 'react'

import RichText from '@/components/__deprecated/RichText'

import IconPlus from '../IconPlus'
import * as styles from './AccordionItem.module.scss'

const AccordionItem = ({heading, richText, isActive, handleClick = () => undefined}) => {
  const contentRef = useRef(null)
  const headingRef = useRef(null)
  const timeline = useMemo(() => gsap.timeline(), [])

  const sharedClassName = classNames({
    [styles.isActive]: isActive,
  })

  useEffect(() => {
    if (contentRef.current) {
      timeline.fromTo(
        contentRef.current,
        {
          height: 0,
          duration: 0.4,
          opacity: 0,
        },
        {height: 'auto', opacity: 1}
      )
    }
  }, [])

  useEffect(() => {
    isActive ? timeline.play() : timeline.timeScale(2).reverse()
  }, [isActive])

  return (
    <div onClick={handleClick} role='button' onKeyPress={handleClick} tabIndex={0}>
      <article className={classNames(styles.article, sharedClassName)}>
        {heading && (
          <h3 className={classNames(styles.heading, sharedClassName)} ref={headingRef}>
            {heading}
          </h3>
        )}

        <div className={classNames(styles.content, sharedClassName)} ref={contentRef}>
          {richText && (
            <RichText
              className={classNames(styles.richText, sharedClassName)}
              richtext={richText}
            />
          )}
        </div>

        <div className={classNames(styles.button, sharedClassName)} aria-label='Show Content'>
          <IconPlus />
        </div>
      </article>
    </div>
  )
}

export default AccordionItem
