import * as React from 'react'

import Accordion from '@/components/__deprecated/Accordion'
import CallToAction from '@/components/__deprecated/CallToAction'
import FooterV2 from '@/components/__deprecated/FooterV2'
import Header from '@/components/__deprecated/Header'
import HeroLarge from '@/components/__deprecated/HeroLarge'
import HeroSmall from '@/components/__deprecated/HeroSmall'
import Legals from '@/components/__deprecated/Legals/Legals'
import Links from '@/components/__deprecated/Links'
import Logos from '@/components/__deprecated/Logos'
import Manifesto from '@/components/__deprecated/Manifesto'
import Navigation from '@/components/__deprecated/Navigation'
import Onboarding from '@/components/__deprecated/Onboarding/Onboarding'
import Pricing from '@/components/__deprecated/Pricing'
import Quote from '@/components/__deprecated/Quote'
import Reviews from '@/components/__deprecated/Reviews'
import SectionV2 from '@/components/__deprecated/SectionV2'
import Showcase from '@/components/__deprecated/Showcase'
import Slider from '@/components/__deprecated/Slider'
import Team from '@/components/__deprecated/Team'
import Testimonials from '@/components/__deprecated/Testimonials'
import GlobalComponent from '@/components/GlobalComponent'

const DynamicComponent = (props) => {
  switch (props.component) {
    case 'accordion':
      return <Accordion {...props} />
    case 'call-to-action':
      return <CallToAction {...props} />
    case 'footer-v2':
      return <FooterV2 {...props} />
    case 'global-component':
      return <GlobalComponent {...props} />
    case 'header':
      return <Header {...props} />
    case 'hero-large':
      return <HeroLarge {...props} />
    case 'hero-small':
      return <HeroSmall {...props} />
    case 'legals':
      return <Legals {...props} />
    case 'links':
      return <Links {...props} />
    case 'logos':
      return <Logos {...props} />
    case 'manifesto':
      return <Manifesto {...props} />
    case 'navigation':
      return <Navigation {...props} />
    case 'onboarding':
      return <Onboarding {...props} />
    case 'pricing':
      return <Pricing {...props} />
    case 'quote':
      return <Quote {...props} />
    case 'reviews':
      return <Reviews {...props} />
    case 'section-v2':
      return <SectionV2 {...props} />
    case 'showcase':
      return <Showcase {...props} />
    case 'slider':
      return <Slider {...props} />
    case 'team':
      return <Team {...props} />
    case 'testimonials':
      return <Testimonials {...props} />
    default:
      return <div>The component {props.component} has not been created yet.</div>
  }
}

export default DynamicComponent
