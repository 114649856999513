import classNames from 'classnames'
import * as React from 'react'

import Link from '@/components/Link'

const themes = {
  primary: 'bg-yellow-500 text-black hover:bg-yellow-100',
  secondary: 'text-yellow-100 bg-black hover:bg-yellow-500 hover:text-black',
}

const Button = ({label, link, id, theme = 'primary'}) => {
  return (
    <Link
      link={link}
      id={id}
      className={classNames('body-m-regular rounded-[64px] py-3 px-8', themes[theme])}
    >
      {label}
    </Link>
  )
}

export default Button
