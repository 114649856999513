import classNames from 'classnames'
import * as React from 'react'

import Image from '@/components/Image'

import * as styles from './TestimonialsItem.module.scss'

const Author = ({heading, label}) => (
  <div className={styles.author}>
    {heading && <h3 className={styles.heading}>{heading}</h3>}
    {label && <p className={styles.label}>{label}</p>}
  </div>
)

const TestimonialsItem = (props) => {
  const {image, paragraph, layout} = props
  const isAlternative = layout === 'alternative'

  return (
    <article className={classNames(styles.article, {[styles.alternative]: isAlternative})}>
      {image && image.filename && (
        <div className={styles.picture}>
          <Image className={styles.image} src={image.filename} alt={image.alt} />
          {isAlternative ? <Author {...props} /> : null}
        </div>
      )}

      <div>
        {isAlternative ? null : <Author {...props} />}
        {paragraph && <p className={styles.paragraph}>{paragraph}</p>}
      </div>
    </article>
  )
}

export default TestimonialsItem
