import * as React from 'react'

import DynamicComponent from '@/components/DynamicComponent'

const GlobalComponent = ({reference}) => {
  if (!reference || !reference.content || !reference.content.body) return null

  return (
    <>
      {reference.content.body.map((props) => (
        <DynamicComponent key={props._uid} {...props} _editable={null} />
      ))}
    </>
  )
}

export default GlobalComponent
