/* eslint-disable no-warning-comments */

import classNames from 'classnames'
import gsap from 'gsap'
import {ScrollTrigger} from 'gsap/dist/ScrollTrigger'
import {debounce} from 'lodash'
import React, {useEffect, useRef, useState} from 'react'

import * as styles from './Marquee.module.scss'

gsap.registerPlugin(ScrollTrigger)

const MarqueeWrapper = ({
  className,
  children,
  pause,
  speed = 0.3,
  velocity = 0.5,
  scrollBack = true,
}) => {
  const [childWidth, setChildWidth] = useState(0)
  const [sectionWidth, setSectionWidth] = useState(0)
  const [count, setCount] = useState(1)

  const sectionRef = useRef(null)
  const itemsRef = useRef(null)
  const itemRef = useRef(null)

  const scrollY = useRef(0)
  const trackPosition = useRef(0)
  const prevScrollY = useRef(0)
  const prevDistance = useRef(0)
  const current = useRef(0)
  const goal = useRef(0)

  useEffect(() => {
    const fillContainer = sectionWidth && childWidth ? Math.ceil(sectionWidth / childWidth) : 1
    setCount(2 + fillContainer)
  }, [sectionWidth, childWidth])

  useEffect(() => {
    const items = itemsRef.current

    const decelerationThreshold = 16

    let requestId

    const reset = () => {
      goal.current -= current.current
      current.current = 0
      trackPosition.current += childWidth
    }

    const lerp = (distance) => {
      current.current +=
        distance * gsap.utils.mapRange(0, 1, 0, 0.1, gsap.utils.clamp(0, 1, velocity))
      trackPosition.current = current.current
    }

    const marquee = () => {
      trackPosition.current -= gsap.utils.mapRange(0, 1, 0, 5, gsap.utils.clamp(0, 1, speed))
      goal.current = trackPosition.current
      current.current = trackPosition.current
    }

    function loop() {
      scrollY.current = window.scrollY

      if (scrollBack) {
        const scrollDiff = scrollY.current - prevScrollY.current || 0
        goal.current -= scrollDiff
      }

      const distance = goal.current - current.current

      if (trackPosition.current <= -childWidth || trackPosition.current >= childWidth) {
        reset()
      }

      if (
        // when accelerating we want lerp
        (distance < 0 && prevDistance.current > distance) ||
        (distance > 0 && distance > prevDistance.current) ||
        // we want lerp when decelerating until a specific threshold
        (distance < 0 && prevDistance.current < distance && distance <= -decelerationThreshold) ||
        (distance > 0 && distance < prevDistance.current && distance >= decelerationThreshold)
      ) {
        lerp(distance)
      } else {
        marquee()
      }

      prevScrollY.current = scrollY.current
      prevDistance.current = distance

      gsap.set(items, {x: trackPosition.current})

      requestId = window.requestAnimationFrame(loop)
    }

    if (pause) {
      if (items) {
        const xPercent = gsap.utils.random(-50, -25)
        gsap.set(Array.from(items.children), {xPercent})
      }
    } else {
      loop()
    }

    return () => {
      if (requestId) window.cancelAnimationFrame(requestId)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [count, childWidth, speed, velocity, pause, scrollBack])

  useEffect(() => {
    const observer = new ResizeObserver((entries) => {
      setChildWidth(entries[0].target.getBoundingClientRect().width)
    })

    if (itemRef.current) observer.observe(itemRef.current)

    return () => {
      observer.disconnect()
    }
  }, [])

  useEffect(() => {
    function handleResize() {
      if (sectionRef.current) {
        setSectionWidth(sectionRef.current.offsetWidth)
      }
    }

    const debouncedHandler = debounce(handleResize, 250)

    const observer = new ResizeObserver(debouncedHandler)

    if (sectionRef.current) {
      observer.observe(sectionRef.current)
    }

    return () => {
      debouncedHandler.cancel()
      observer.disconnect()
    }
  }, [])

  return (
    <section className={classNames(styles.marquee, className)} ref={sectionRef}>
      <div aria-hidden='true' ref={itemsRef} className={styles.items}>
        {Array.from(Array(count)).map((_, i) => (
          <div key={i} className={styles.item} ref={itemRef}>
            {children}
          </div>
        ))}
      </div>
    </section>
  )
}

export default MarqueeWrapper
