import * as React from 'react'

const useIsPageReady = () => {
  const [isPageReady, setIsPageReady] = React.useState(
    typeof window === 'undefined' ? false : window.isPageReady
  )

  React.useEffect(() => {
    const listenerAction = () => setIsPageReady(true)

    if (window.isPageReady) {
      setIsPageReady(true)
    } else {
      window.addEventListener('isPageReady', listenerAction)
      return () => window.removeEventListener('isPageReady', listenerAction)
    }
  }, [])

  return {isPageReady}
}

export default useIsPageReady
