// extracted by mini-css-extract-plugin
export var container = "Testimonials-module--container--dc298";
export var controls = "Testimonials-module--controls--f08c0";
export var divider = "Testimonials-module--divider--6264c";
export var iconNext = "Testimonials-module--iconNext--7dc88";
export var iconPrev = "Testimonials-module--iconPrev--38f69";
export var index = "Testimonials-module--index--ffab3";
export var isActive = "Testimonials-module--isActive--f8a39";
export var item = "Testimonials-module--item--7ec27";
export var items = "Testimonials-module--items--50d0c";
export var marquee = "Testimonials-module--marquee--3ea4e";
export var marqueeText = "Testimonials-module--marqueeText--df416";
export var section = "Testimonials-module--section--64080";
export var transparentBackground = "Testimonials-module--transparentBackground--3ed2a";