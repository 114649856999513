import * as React from 'react'

import Link from '@/components/Link'

import ArrowYellow from './ArrowYellow'
import * as styles from './LinksItem.module.scss'

const LinksItem = ({label, link}) => {
  return (
    <Link link={link} className={styles.link}>
      <ArrowYellow className={styles.icon} />
      {label}
    </Link>
  )
}

export default LinksItem
