import classNames from 'classnames'
import * as React from 'react'

import RichText from '@/components/__deprecated/RichText'
import Image from '@/components/Image'

import OvalLine from '../OvalLine'
import * as styles from './TeamItem.module.scss'

const TeamItem = ({image, heading, subheading, richtext, isReversed}) => {
  const sharedClassName = classNames({
    [styles.isReversed]: isReversed,
  })

  return (
    <article className={classNames(styles.article, sharedClassName)}>
      {image && image.filename && (
        <div className={classNames(styles.picture, sharedClassName)}>
          <OvalLine />
          <Image className={styles.image} {...image} />
        </div>
      )}

      <div className={classNames(styles.content, sharedClassName)}>
        {heading && <h4 className={styles.heading}>{heading}</h4>}
        {subheading && <p className={styles.subheading}>{subheading}</p>}
        {richtext && (
          <div className={styles.paragraph}>
            <RichText richtext={richtext} />
          </div>
        )}
      </div>
    </article>
  )
}

export default TeamItem
