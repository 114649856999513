import classNames from 'classnames'
import gsap from 'gsap'
import React, {useEffect, useRef, useState} from 'react'

import Button from '@/components/__deprecated/Button'
import SliderItem from '@/components/__deprecated/Slider/SliderItem'

import ArrowButton from '../ArrowButton'
import * as styles from './Slider.module.scss'

gsap.registerPlugin()

const Slider = ({heading, paragraph, items = [], buttonLabel, buttonLink, label}) => {
  const [active, setActive] = useState(0)
  const imageItems = useRef([])

  const createImageItemsRefs = (item, index) => {
    if (item) {
      imageItems.current[index] = item
    }
  }

  // eslint-disable-next-line no-warning-comments
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const handleClick = (direction) => {
    let activeElement = active

    if (direction === 'prev') {
      activeElement += 1
      activeElement >= imageItems.current.length && (activeElement = 0)
      handleSlides('prev', active)
    }

    if (direction === 'next') {
      activeElement -= 1
      activeElement < 0 && (activeElement = imageItems.current.length - 1)
      handleSlides('next', activeElement)
    }

    setActive(activeElement)
  }

  useEffect(() => {
    const screenSize = window.matchMedia('(min-width: 1280px)')

    if (screenSize.matches) {
      const interval = setInterval(() => handleClick('prev'), 5000)
      return () => clearInterval(interval)
    }
  }, [handleClick])

  const handleSlides = (direction, active) => {
    const imageItem = [...imageItems.current]
    const tl = gsap.timeline()

    imageItem.forEach((_, index) => {
      if (direction === 'prev') {
        if (active === index) {
          tl.from(
            imageItems.current[index],
            {yPercent: 200, duration: 0.5, delay: 0.3, ease: 'power3.out'},
            0
          )
        } else {
          tl.fromTo(
            imageItems.current[index],
            {yPercent: 200},
            {yPercent: 0, duration: 0.5, delay: 0.3, ease: 'power3.out'},
            0
          )
        }
      }

      if (direction === 'next') {
        if (active === index) {
          tl.fromTo(
            imageItems.current[index],
            {yPercent: 200},
            {yPercent: 0, duration: 0.5, delay: 0.3, ease: 'power3.out'},
            0
          )
        } else {
          tl.from(
            imageItems.current[index],
            {yPercent: 200, duration: 0.5, delay: 0.3, ease: 'power3.out'},
            0
          )
        }
      }
    })
  }

  return (
    <section className={styles.section}>
      {heading && <h3 className={styles.heading}>{heading}</h3>}
      {paragraph && <p className={styles.paragraph}>{paragraph}</p>}

      <div className={styles.container}>
        <div className={styles.textItems}>
          {items.map((item, index) => {
            return (
              <div
                key={item._uid}
                className={classNames(styles.textItem, {[styles.isActive]: index === active})}
              >
                <SliderItem {...item} hasNoImage />
              </div>
            )
          })}
        </div>

        <div className={styles.imageItems}>
          {items.map((item, index) => {
            return (
              <div
                key={item._uid}
                ref={(element) => {
                  createImageItemsRefs(element, index)
                }}
                className={classNames(styles.imageItem, {[styles.isActive]: index === active})}
              >
                <SliderItem {...item} hasNoLabel />
              </div>
            )
          })}
        </div>

        <div className={styles.controls}>
          <button onClick={() => handleClick('next')} aria-label='Next Slide'>
            <ArrowButton className={styles.iconPrev} />
          </button>

          <div className={styles.index}>
            <p>{active < 10 ? `0${active + 1}` : active + 1}</p>
            <div className={styles.divider} />
            <p>{items.length < 10 ? `0${items.length}` : items.length}</p>
          </div>

          <button onClick={() => handleClick('prev')} aria-label='Previous Slide'>
            <ArrowButton className={styles.iconNext} />
          </button>
        </div>
      </div>

      {buttonLabel && buttonLink && (
        <Button className={styles.button} isSecondary label={buttonLabel} link={buttonLink} />
      )}

      {label && <p className={styles.label}>{label}</p>}
    </section>
  )
}

export default Slider
