import * as React from 'react'

import Image from '@/components/Image'
import Link from '@/components/Link'

import * as styles from './LogosItem.module.scss'

const LogosItem = ({link, logo}) => {
  return (
    <div className={styles.picture}>
      <Link link={link}>
        <Image className={styles.image} {...logo} />
      </Link>
    </div>
  )
}

export default LogosItem
