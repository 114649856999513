// extracted by mini-css-extract-plugin
export var button = "PricingCard-module--button--7b858";
export var card = "PricingCard-module--card--aa0dc";
export var container = "PricingCard-module--container--dd5b1";
export var description = "PricingCard-module--description--6b603";
export var descriptionDetails = "PricingCard-module--descriptionDetails--2e330";
export var divider = "PricingCard-module--divider--8e589";
export var footer = "PricingCard-module--footer--855b8";
export var header = "PricingCard-module--header--d5855";
export var isFirst = "PricingCard-module--isFirst--b2902";
export var item = "PricingCard-module--item--599a5";
export var items = "PricingCard-module--items--0eccd";
export var price = "PricingCard-module--price--9c4b2";
export var title = "PricingCard-module--title--b7ae4";