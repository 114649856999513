// extracted by mini-css-extract-plugin
export var background = "HeroLarge-module--background--69031";
export var backgroundDark = "HeroLarge-module--backgroundDark--e7846";
export var backgroundLight = "HeroLarge-module--backgroundLight--ffac1";
export var button = "HeroLarge-module--button--203dc";
export var container = "HeroLarge-module--container--414e6";
export var dark = "HeroLarge-module--dark--32504";
export var gradient = "HeroLarge-module--gradient--a7374";
export var gradients = "HeroLarge-module--gradients--5d385";
export var heading = "HeroLarge-module--heading--54493";
export var image = "HeroLarge-module--image--b43eb";
export var images = "HeroLarge-module--images--98d37";
export var light = "HeroLarge-module--light--66d79";
export var paragraph = "HeroLarge-module--paragraph--724d6";
export var section = "HeroLarge-module--section--5400b";