import * as React from 'react'

function LogoText({title, titleId, ...props}) {
  return (
    <svg
      width={64}
      height={32}
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      aria-labelledby={titleId}
      {...props}
    >
      {title ? <title id={titleId}>{title}</title> : null}
      <path
        d='M3.2 23.68h3.063v-6.674h6.743v-2.743H6.263v-3.52h7.566V8H3.2v15.68zm17.817.32c3.703 0 6.195-2.423 6.195-6.034 0-3.612-2.492-6.035-6.195-6.035-3.703 0-6.194 2.423-6.194 6.035 0 3.611 2.491 6.034 6.194 6.034zm0-2.423c-2.057 0-3.451-1.44-3.451-3.611 0-2.172 1.394-3.612 3.451-3.612 2.058 0 3.452 1.44 3.452 3.612 0 2.171-1.395 3.611-3.452 3.611zm8.068 2.103h2.743V8h-2.742v15.68zm10.879.32c1.394 0 2.925-.64 3.794-1.577v1.257H46.5V8h-2.743v5.509c-.869-.938-2.4-1.578-3.794-1.578-3.612 0-6.035 2.423-6.035 6.035 0 3.611 2.423 6.034 6.035 6.034zm.274-2.423c-2.126 0-3.566-1.44-3.566-3.611 0-2.172 1.44-3.612 3.566-3.612 2.103 0 3.52 1.44 3.52 3.612 0 2.171-1.417 3.611-3.52 3.611zM54.8 24c2.788 0 5.165-1.691 5.92-4.206h-2.79c-.525 1.143-1.782 1.92-3.13 1.92-1.783 0-3.155-1.211-3.406-2.994h9.371l.229-.229v-.525c0-3.612-2.492-6.035-6.194-6.035-3.703 0-6.195 2.423-6.195 6.035 0 3.611 2.492 6.034 6.195 6.034zm-3.223-7.566a3.447 3.447 0 013.223-2.217c1.44 0 2.72.892 3.222 2.217h-6.445z'
        fill='#171717'
      />
    </svg>
  )
}

export default LogoText
