import * as React from 'react'

import Link from '@/components/Link'

import LogoText from '../LogoText'
import * as styles from './Header.module.scss'

const Header = ({link, label}) => {
  return (
    <div className={styles.container}>
      <header className={styles.header}>
        <LogoText />
        {link && label && (
          <Link link={link} className={styles.link}>
            {label}
          </Link>
        )}
      </header>
    </div>
  )
}

export default Header
