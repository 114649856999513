import * as React from 'react'

const ArrowYellow = ({className}) => (
  <svg className={className} width='48' height='48' fill='none' xmlns='http://www.w3.org/2000/svg'>
    <path
      d='M5 24h38m0 0c-13.24 0-18.24 8.914-17.733 12M43 24c-13.24 0-18.24-8.914-17.733-12'
      stroke='#FFE999'
    />
  </svg>
)

export default ArrowYellow
