import * as React from 'react'

import LogosItem from '@/components/__deprecated/Logos/LogosItem'
import RichText from '@/components/__deprecated/RichText'

import * as styles from './Logos.module.scss'

const Logos = ({heading, logos = []}) => {
  return (
    <section className={styles.section}>
      <div className={styles.heading}>{heading && <RichText richtext={heading} />}</div>

      <div className={styles.logos}>
        {logos.map((props) => (
          <div key={props._uid}>
            <LogosItem {...props} />
          </div>
        ))}
      </div>
    </section>
  )
}

export default Logos
